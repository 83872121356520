.nalog {
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(4px);
    z-index: 2;
    border: 1px solid white;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
}

.nav-options {
    display: flex;
    align-items: center;
}

.logo {
    max-width: 65px;
    /* You can adjust this */
    padding: 0.5rem;
}


.head,
.link a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    margin-left: 15px;
}

.link a:hover {
    color: darkcyan;
    transition: .5s all;
}

.head:hover {
    color: darkcyan;
    transition: all .5s;
}


.toggle_btn {
    display: none;
    /* Initially hide the button */
    font-size: 24px;
    cursor: pointer;
    color: white;
}

.link {
    list-style: none;
    display: flex;
    gap: 20px;
    margin-top: 1rem;
}



@media (max-width: 768px) {
    .link {
        display: none;
        flex-direction: column;
        position: absolute;
        width: 100%;
        top: 69px;
        text-align: center;
        left: 0;
        background-color: rgba(40, 47, 47, 0.612);
        border-radius: 20px;
    }

    .link li a {
        color: white;
    }

    .link.active {
        display: flex;
    }

    .toggle_btn {
        display: block;
        /* Show the button on mobile */
    }
}
