.Candlelit-Body{
    background-image: url(../imgs/Beauti.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
    background-attachment: fixed;
}



.section-1-CD{
    /* border: 2px solid orange; */
    display: flex;
    height:65rem ;
    width: 100%;
}
.left-div-CD{
    /* border: 2px solid rgb(255, 77, 0); */
    height:50rem ;
    width: 50%;
    margin-top: 8rem;
    padding: 4rem;
}
.right-div-CD{
    /* border: 2px solid rgb(0, 221, 255);  */
    height:50rem ;
    width: 50%;
    margin-top: 8rem;
    margin-left: 2rem;
}
.section-2-h1-right-CD{
    font-size: 2rem;
}
.section-2-para-right-CD{
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 1rem;
}
.section-2-para-2-right-CD{
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 0.7rem;
}
.BookNow-btn-CD {
    background-color: #00000029;
    height: 3rem;
    width: 11rem;
    border-radius: 2rem;
    border: double;
    margin-left: 7rem;
    margin-top: 1rem;
    backdrop-filter: blur(4px);
}

.BookNow-btn-CD:hover {
    background-color: darkcyan;
    color: black;
    outline: auto;
    transition: 0.5s;
}


@media screen and (max-width:1200px) {
    
.section-2-para-right-CD{
    line-height: 1.2rem;
    margin-top: 0rem;
}
}
@media screen and (max-width:1024px){
    .section-1-CD{
        flex-direction: column;
        width: 100%;
        height: 104rem;
    }
    
    .right-div-CD{
        width: 100%;
        height: 45rem;
        margin-left: 0rem;
    }
    .section-2-h1-right-CD {
        margin-left: 1rem;
    }
    .section-2-para-right-CD{
        margin-left: 2rem;
    }
    
    .left-div-CD {
        height: 35rem;
        width: 100%;
        padding: 6rem;
        margin-top: -7rem;
        margin-top: -7rem;
}
}
@media screen and (max-width:768px){
.right-div-CD{
  height: 42rem;
}
}
@media screen and (max-width:480px){
    .section-1-CD{
        /* border: 2px solid greenyellow; */
        height: 120rem;
    }
    .left-div-CD {
        height: 16rem;
        padding: 2rem;
        margin-top: -3.5rem;
    }
.section-2-h1-right-CD {
    text-align: center;
}
.section-2-para-right-CD {
    text-align: center;
    margin-left: 0rem;
    padding: 0.5rem;
}
.section-2-para-2-right-CD {
    text-align: center;
    margin-left: 0rem;
}
.BookNow-btn-CD{
    margin-left: 7rem;
}
.right-div-CD {
    height: 55rem;
}
}
@media screen and (max-width:430px){
  .BookNow-btn-CD{
    margin-left: 7.5rem;
}
}
@media screen and (max-width:395px){
  .BookNow-btn-CD{
    margin-left: 6.5rem;
}
}
@media screen and (max-width:376px){
  .BookNow-btn-CD{
    margin-left: 6.2rem;
}
}

@media screen and (max-width:360px){
  .BookNow-btn-CD{
    margin-left: 6rem;
}
.left-div-CD {
    margin-top: 3rem;
}
}

span{
    color: #00ffe5;
    font-weight: bolder;
}
