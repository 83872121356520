.Propose-Body{
    background-image: url(../imgs/Beauti.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
    background-attachment: fixed;
}


.section-1-PYSH{
    /* border: 2px solid orange; */
    display: flex;
    height:65rem ;
    width: 100%;
}
.left-div-PYSH{
    /* border: 2px solid rgb(255, 77, 0); */
    height:50rem ;
    width: 50%;
    margin-top: 8rem;
    padding: 4rem;
}
.right-div-PYSH{
    /* border: 2px solid rgb(0, 221, 255);  */
    height:50rem ;
    width: 50%;
    margin-top: 8rem;
    margin-left: 2rem;
}
.section-2-h1-right-PYSH{
    font-size: 2rem;
}
.section-2-para-right-PYSH{
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 1rem;
}
.BookNow-btn-PYSH {
    background-color: #00000029;
    height: 3rem;
    width: 11rem;
    border-radius: 2rem;
    border: double;
    margin-left: 7rem;
    margin-top: 1rem;
    backdrop-filter: blur(4px);
}

.BookNow-btn-PYSH:hover {
    background-color: darkcyan;
    color: black;
    outline: auto;
    transition: 0.5s;
}


@media screen and (max-width:1200px) {
    
.section-2-para-right-PYSH{
    line-height: 1.2rem;
    margin-top: 0rem;
}
}
@media screen and (max-width:1024px){
    .section-1-PYSH{
        flex-direction: column;
        width: 100%;
        height: 100rem;
    }
    .left-div-PYSH{
        width: 100%;
    }
    .right-div-PYSH{
        width: 100%;
        height: 45rem;
        margin-left: 0rem;
    }
    .section-2-h1-right-PYSH {
        margin-left: 1rem;
    }
    .section-2-para-right-PYSH{
        margin-left: 2rem;
    }
    .left-div-PYSH{
        margin-top: 0rem;
    }
    .left-div-PYSH {
        height: 35rem;
        padding: 6rem;
}
}
@media screen and (max-width:768px){
.right-div-PYSH{
  height: 41rem;

}
.section-1-PYSH{
  height: 100rem;
}
}
@media screen and (max-width:480px){
    .section-1-PYSH{
        /* border: 2px solid greenyellow; */
        height: 100rem;
    }
    .left-div-PYSH {
        height: 33rem;
        padding: 2rem;
        margin-top: 0rem;
    }
.section-2-h1-right-PYSH {
    text-align: center;
}
.section-2-para-right-PYSH {
    text-align: center;
    margin-left: 0rem;
    padding: 0.5rem;
}
.BookNow-btn-PYSH{
    margin-left: 7rem;
}
.right-div-PYSH {
    height: 72rem;
}
}
@media screen and (max-width:430px){
  .BookNow-btn-PYSH{
    margin-left: 8rem;
}
}
@media screen and (max-width:415px){
  .BookNow-btn-PYSH{
    margin-left: 7.1rem;
}
}
@media screen and (max-width:375px){
  .BookNow-btn-PYSH{
    margin-left: 6.3rem;
}
}

span{
    color: #00ffe5;
    font-weight: bolder;
}
