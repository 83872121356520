* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Bodoni Moda', serif;
    color: white;
    scroll-behavior: smooth;
}

.Packages-Body {

    background-image: url(../imgs/Beauti.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    background-attachment: fixed;
    
}
/* SECTION-1 */

.section-1-packages {
    /* border: 2px solid yellow; */
    display: flex;
    height: 40rem;
    width: 100%;
}

.head-left {
    margin-top: 10rem;
    height: 30rem;
    width: 50rem;
}

.head-heading {
    margin-left: 7rem;
    font-size: 5rem;
    font-weight: 600;
}

.head-para {
    margin-left: 7rem;
    font-size: 2rem;
    margin-top: 2rem;
}

.head-btn {
    background-color: #00000029;
    height: 3rem;
    width: 11rem;
    border-radius: 2rem;
    border: double;
    margin-left: 7rem;
    margin-top: 4rem;
    backdrop-filter: blur(4px);
}

.head-btn:hover {
    background-color: darkcyan;
    color: rgb(0, 0, 0);
    outline: auto;
    transition: 0.5s;
}

.head-btn-2 {
    background-color: #00000029;
    height: 3rem;
    width: 11rem;
    border-radius: 2rem;
    border: double;
    margin-left: 1rem;
    margin-top: 4rem;
    backdrop-filter: blur(4px);
}

.head-btn-2:hover {
    background-color: darkcyan;
    color: black;
    outline: auto;
    transition: 0.5s;
}

.head-right {
    height: 30rem;
    width: 45rem;
    margin-top: 10rem;
}

.head-images {
    height: 15rem;
    width: 10rem;
    margin-top: 4rem;
    margin-left: 1rem;
    outline: 1px solid white;
}

@media screen and (max-width:1200px) {
    .head-images {
        height: 13rem;
        width: 8.5rem;
    }
}
@media screen and (max-width:1024px) {

    .head-left {
        width: 34rem;
    }

    .head-heading {
        margin-left: 3rem;
        font-size: 3rem;
    }

    .head-para {
        margin-left: 3.5rem;
        font-size: 1.4rem;
    }

    .head-btn {
        width: 9rem;
        margin-left: 2.5rem;
    }

    .head-btn-2 {
        width: 9rem;
        margin-left: 0rem;
    }

    .head-images {
        height: 12rem;
        width: 7.5rem;
    }
}
@media screen and (max-width:768px) {
    .section-1-packages {
        display: flex;
        flex-direction: column;
        width: 48rem;
        height: 55rem;
    }

    .head-left {
        margin-left: 6rem;
    }

    .head-heading {
        font-size: 4rem;
        text-align: center;
    }

    .head-para {
        text-align: center;
        font-size: 2rem;
    }

    .head-btn {
        margin-left: 9.5rem;
    }

    .head-right {
        margin-top: 1rem;
        width: 33rem;
        margin-left: 10.5rem;
    }

    .head-images {
        height: 13rem;
        width: 8.5rem;
    }
}
@media screen and (max-width:480px){
    .section-1-packages {
        width: 100%;
        height: 50rem;
        /* border: 2px solid red; */
    }
    .head-left {
        width: 100%;
        margin-left: 0rem;
        height: 25rem;
        /* border: 2px solid blue; */
    }
    .head-heading{
        margin-left: 0rem;
        font-size: 3rem;
    }
    .head-para {
        margin-left: 0rem;
        font-size: 1.5rem;
        padding: 1rem;
    }
    .head-btn {
        margin-left: 3.5rem;
    }
    .head-right {
            /* border: 2px solid yellow; */
            margin-top: 1rem;
            width: 100%;
            height: 13rem;
            margin-left: 0rem;
        
    }
    .head-images {
        height: 9rem;
        width: 6rem;
        margin-top: 0rem;
        margin-left: 2rem;   
    }
}
@media screen and (max-width:395px){
.head-images {
    margin-left: 1.6rem;
}
}
@media screen and (max-width:395px){
    .head-btn {
        margin-left: 2.5rem;
    }
.head-images {
    margin-left: 1.5rem;
}
}
@media screen and (max-width:395px){
.head-images {
    margin-left: 1.2rem;
}
}


.section-2_2{
    /* border: 2px solid red ; */
    height: 38rem;
    width: 100%;
}
.section-2_2-h1{
    /* border: 2px solid rgb(251, 255, 0); */
    text-align: center;
    font-size: 5rem;
}
.main-div{
    /* border: 2px solid rgb(255, 0, 234); */
    display: flex;
    height: 47rem;
    width: 100%;
}

.left-div-packages-1{
    /* border: 2px solid rgb(0, 204, 255); */
    height: 38rem;
    width: 36rem;
    
}
.left-image {
    height: 24rem;
    width: 20rem;
    margin-left: 10rem;
    margin-top: 3rem;
}
.right-div-Packages{
    /* border: 2px solid rgb(6, 253, 122); */
    height: 38rem;
    width: 48rem;
    margin-left: -4rem;
    margin-top: 0rem;
}
.section-2_2-h1-right{
    margin-top: 9rem;
    margin-left: 1rem;
}
.section-2_2-para-right{
    margin-left: 2rem;
    margin-top: 2rem;
    font-size: 1.2rem;
}
.BookNow-btn {
    background-color: #00000029;
    height: 3rem;
    width: 11rem;
    border-radius: 2rem;
    border: double;
    margin-left: 7rem;
    margin-top: 1rem;
    backdrop-filter: blur(4px);
}

.BookNow-btn:hover {
    background-color: darkcyan;
    color: black;
    outline: auto;
    transition: 0.5s;
}

@media screen and (max-width:1200px) {
    .section-2_2{
    height: 50rem;
    }
    .left-image{
        margin-left: 6rem;
    }
    .section-2_2-para-right{
        padding: 1rem;
    }
}
@media screen and (max-width:1024px){
    .section-2_2 {
        height: 52rem;
    }
    .section-2_2-h1 {
        font-size: 3.5rem;
    }
    .left-image {
        margin-left: -1rem;
    }
    .section-2_2-para-right {
        padding: 0rem;
    }
    .BookNow-btn {
        margin-top: 1rem;
    }
}
@media screen and (max-width:835px){
    .section-2_2-h1-right {
        margin-top: 9rem;
        margin-left: 3rem;
    }
    .section-2_2-para-right {
        margin-left: 3rem;
    }
}


@media screen and (max-width:768px){
.section-2_2{
    width: 48rem;
    height: 52rem;
}
.section-2_2-h1-right {
    margin-top: 4rem;
    margin-left: 5rem;
}
.section-2_2-para-right {
    margin-left: 5rem;
}
.BookNow-btn {
    margin-left: 9.5rem;
}
}
@media screen and (max-width:480px){
    .section-2_2{
        width: 100%;
        height: 34rem;
        /* border: 2px solid yellow; */
    }
    .main-div {
        /* border: 2px solid rgb(255, 0, 234); */
        display: flex;
        height: 30rem;
        width: 100%;
    }
    .left-div-packages-1 {
    /* border: 2px solid rgb(0, 204, 255); */
    height: 20rem;
    width: 49%;
}
    .section-2_2-h1 {
        font-size: 3rem;
    }
    .left-image {
        margin-left: 0rem;
        height: 13rem;
    width: 9rem;
    }

    .right-div-Packages {
        /* border: 2px solid rgb(6, 253, 122); */
        height: 27rem;
        width: 49%;
        margin-left: 0rem;
    }
    .section-2_2-h1-right {
        margin-top: 3rem;
        margin-left: 0rem;
        font-size: 1.2rem;
    }
    .section-2_2-para-right {
        margin-left: 1rem;
        margin-top: 1rem;
        font-size: 0.7rem;
    }
    .BookNow-btn {
        margin-left: 3rem;
        width: 7rem;
        height: 2.5rem;
        font-size: 0.7rem;
    }
}

/*lines css*/

.line-1{
    height: 6rem;
    /* background-color: red; */
    width: 56.3%;
    margin-left: 21rem;
    margin-top: 4rem;
    border-bottom: dashed #00ffe5;
    border-left: dashed #00ffe5;
}
.line-2{
    height: 6rem;
    width: 53%;
    border-right: dashed #00ffe5;
    margin-left: 24rem; 
    /* background-color: rgb(149, 255, 0); */
}
.line-3{
    height: 6rem;
    /* background-color: red; */
    width: 53%;
    margin-left: 24rem;
    margin-top: -11rem;
    border-bottom: dashed #00ffe5;
    border-right: dashed #00ffe5;
}
.line-4{
    height: 6rem;
    width: 53%;
    border-left: dashed #00ffe5;
    margin-left: 24rem; 
    /* background-color: rgb(149, 255, 0); */
}
.line-5{
    height: 6rem;
    /* background-color: red; */
    width: 53%;
    margin-left: 24rem;
    margin-top: -11rem;
    border-bottom: dashed #00ffe5;
    border-left: dashed #00ffe5;
}
.line-6{
    height: 6rem;
    width: 53%;
    border-right: dashed #00ffe5;
    margin-left: 24rem; 
    /* background-color: rgb(149, 255, 0); */
}

@media screen and (max-width:1200px) {
    .line-1{
        width: 60%;
    margin-left: 16rem;
    margin-top: -11rem;
    }
    .line-2{
        width: 60%;
    margin-left: 16rem;
    }
    .line-3{
        width: 63%;
    margin-left: 16rem;
    }
    .line-4{
        width: 63%;
    margin-left: 16rem;
    }
    .line-5{
        width: 60%;
    margin-left: 16rem;
    margin-top: -16rem;
    }
    .line-6{
        width: 60%;
    margin-left: 16rem;
    }
}

@media screen and (max-width:1115px){
    .line-1{
        margin-top: 0rem;
    }
    .left-image {
        margin-left: 3rem;
    }
}
@media screen and (max-width:1024px){
.line-1 {
    height: 10rem;
    width: 55%;
    margin-left: 12rem;
    margin-top: -12rem;
}
.line-2{
    height: 10rem;
    width: 55%;
    margin-left: 12rem;
}
.line-3 {
    height: 10rem;
    width: 48%;
    margin-top: -14rem;
    margin-left: 16rem;
}
.line-4{
    height: 10rem;
    width: 48%;
}
.line-5 {
    height: 10rem;
    width: 48%;
    margin-left: 15rem;
    margin-top: -16rem;
}
.line-6{
    height: 10rem;
    width: 48%;
    margin-left: 15rem;
}
}
@media screen and (max-width:768px){

.line-1 {
    height: 8rem;
    width: 48.5%;
    margin-left: 12rem;
    margin-top: -7rem;
}
.line-2 {
    height: 8rem;
    width: 55%;
    margin-left: 9rem;
}
.line-3{
    margin-left: 15rem;
    margin-top: -12rem;
    width: 44%;
}
.line-4{
    margin-left: 15rem;
}
.line-5 {
    height: 8rem;
    width: 44%;
    margin-left: 14rem;
    margin-top: -7rem;
}
.line-6 {
    height: 8rem;
    width: 55%;
    margin-left: 9rem;
}
}
@media screen and (max-width:430px){

    .line-1{
margin-left: 6rem;
}
.line-2{
margin-left: 4.5rem;
}
.line-3 {
    margin-left: 6.7rem;
    width: 48%;
}
.line-4 {
    margin-left: 6.5rem;
}

.line-5 {
    margin-left: 6.7rem;
    width: 47%;
}
.line-6{

    margin-left: 5rem;

}

}

/*section-3_3*/


.section-3_3{
    /* border: 2px solid rgb(72, 0, 255) ; */
    height: 45rem;
    width: 100%;
    margin-top: 1rem;
}
.section-3_3-main-div{
    display: flex;
    height: 38rem;
    width: 100%;
}
.section-3_3-right-div-Packages{
 /* border: 2px solid rgb(6, 253, 122); */
 height: 38rem;
 width: 48rem;
 margin-left: 4rem;
}
.section-3_3-h1-right{
    margin-top: 5rem;
    margin-left: 1rem;
}
.section-3_3-para-right{
    margin-left: 2rem;
    margin-top: 2rem;
    font-size: 1.2rem;
}
.section-3_3-BookNow-btn{
    background-color: #00000029;
    height: 3rem;
    width: 11rem;
    border-radius: 2rem;
    border: double;
    margin-left: 7rem;
    margin-top: 1rem;
    backdrop-filter: blur(4px);
}
.section-3_3-BookNow-btn:hover{
    background-color:darkcyan;
    color: black;
    outline: auto;
    transition: 0.5s;
}

.section-3_3-left-div-packages-1{
  /* border: 2px solid rgb(0, 204, 255); */
  height: 38rem;
  width: 36rem;
}
.section-3_3-left-image{
    height: 24rem;
    width: 20rem;
    margin-left: 10rem;
    margin-top: 4rem;
}


@media screen and (max-width:1200px) {
    .section-3_3{
        height: 47rem;
    }
    .section-3_3-left-image{
        margin-left: 6rem;
    }
    .section-3_3-para-right{
        padding: 1rem;
    }
}
@media screen and (max-width:1024px){
    
    .section-3_3-left-image {
        margin-left: 2rem;
    }
    .section-3_3-para-right {
        padding: 0rem;
        font-size: 1rem;
    }
    .section-3_3-BookNow-btn{
        margin-top: 1rem;
    }
}
@media screen and (max-width:885px){
.section-3_3-right-div-Packages {
    margin-left: 1.5rem;
}
.section-3_3-left-image {
    margin-left: 0rem;
    margin-right: 2.5rem;
}
}
@media screen and (max-width:768px){
.section-3_3{
    width: 48rem;
    height: 48rem;
}
}
@media screen and (max-width:480px){
    .section-3_3 {
        /* border: 2px solid red; */
        width: 100%;
        height: 35rem;
        margin-top: 0rem;
    }
    .section-3_3-main-div {
        height: 36rem;
        width: 100%;
    }
    .section-3_3-right-div-Packages {
        /* border: 2px solid rgb(6, 253, 122); */
        height: 35rem;
        width: 50%;
        margin-left: 0rem;
    }
    .section-3_3-h1-right{
    margin-top: 3rem;
    margin-left: 1.5rem;
    font-size: 1.2rem;
}
.section-3_3-para-right {
    margin-left: 2rem;
    margin-top: 1rem;
    font-size: 0.7rem;
}
.section-3_3-BookNow-btn{
    margin-left: 3rem;
    width: 7rem;
    height: 2.5rem;
    font-size: 0.7rem;
}
.section-3_3-left-div-packages-1 {
    /* border: 2px solid rgb(0, 204, 255); */
    height: 35rem;
    width: 48%;
}
.section-3_3-left-image {
    margin-left: 2rem;
    height: 13rem;
    width: 9rem;
}
}
@media screen and (max-width:430px){

.section-3_3-left-image {
    margin-right: 1.5rem;
}
}
@media screen and (max-width:395px){

    .section-3_3-left-image {
        margin-right: 0.5rem;
    }
    }
@media screen and (max-width:360px){

.section-3_3-left-image {
        margin-left: 1.5rem;
    margin-right: 0rem;
}
}

/*section-4_4*/

.section-4_4{
    /* border: 2px solid rgb(255, 0, 183) ; */
    height: 45rem;
    width: 100%;
    margin-top: 1rem;
}
.section-4_4-main-div{
    display: flex;
    height: 38rem;
    width: 100%;
}
.section-4_4-right-div-Packages{
 /* border: 2px solid rgb(6, 253, 122); */
 height: 38rem;
 width: 48rem;
 margin-left: 4rem;
}
.section-4_4-h1-right{
    margin-top: 9rem;
    margin-left: 1rem;
}
.section-4_4-para-right{
    margin-left: 2rem;
    margin-top: 2rem;
    font-size: 1.2rem;
}
.section-4_4-BookNow-btn{
    background-color: #00000029;
    height: 3rem;
    width: 11rem;
    border-radius: 2rem;
    border: double;
    margin-left: 7rem;
    margin-top: 1rem;
    backdrop-filter: blur(4px);
}
.section-4_4-BookNow-btn:hover{
    background-color: darkcyan;
    color: black;
    outline: auto;
    transition: 0.5s;
}

.section-4_4-left-div{
  /* border: 2px solid rgb(0, 204, 255); */
  height: 38rem;
  width: 36rem;
}
.section-4_4-left-image{
    height: 28rem;
    width: 20rem;
    margin-left: 14rem;
    margin-top: 2rem;
}


@media screen and (max-width:1200px) {
        .section-4_4{
        height: 50rem;
        }
        .section-4_4-left-image{
            margin-left: 6rem;
        }
        .section-4_4-para-right{
            padding: 1rem;
        }
    }
    @media screen and (max-width:1024px){
        .section-4_4 {
            height: 52rem;
        }
        .section-4_4-h1 {
            font-size: 3.5rem;
        }
        .left-image {
            margin-left: 2rem;
        }
        .section-4_4-para-right {
            padding: 0rem;
        }
        .section-4_4-BookNow-btn {
            margin-top: 1rem;
        }
    
    }
    @media screen and (max-width:885px){
    .section-4_4-right-div-Packages{
    margin-left: 1rem;
    margin-top: -3rem;
}
.section-4_4-left-image {
    margin-left: 5rem;
}
}
    @media screen and (max-width:768px){
    .section-4_4{
        width: 48rem;
        height: 52rem;
    }
    .section-4_4-BookNow-btnn {
        margin-left: 1.5rem;
    }
    }
    @media screen and (max-width:480px){
        .section-4_4{
            width: 100%;
            height: 26rem;
            margin-top: 0rem;
            /* border: 2px solid yellow; */
        }
        .section-4_4-main-div{
            /* border: 2px solid rgb(255, 0, 234); */
            display: flex;
            height: 26rem;
            width: 100%;
        }
        .section-4_4-left-div {
        /* border: 2px solid rgb(0, 204, 255); */
        height: 20rem;
        width: 49%;
    }
        
        .section-4_4-left-image{
            margin-left: 2rem;
            height: 13rem;
        width: 9rem;
        }

        .section-4_4-right-div-Packages{
            /* border: 2px solid rgb(6, 253, 122); */
            height: 25rem;
            width: 49%;
            margin-left: 1rem;
            margin-top: 0;
        }
        .section-4_4-h1-right {
            margin-top: 3rem;
            margin-left: 0rem;
            font-size: 1.2rem;
        }
        .section-4_4-para-right {
            margin-left: 1rem;
            margin-top: 1rem;
            font-size: 0.7rem;
        }
        .section-4_4-BookNow-btn {
            margin-left: 3rem;
            width: 7rem;
            height: 2.5rem;
            font-size: 0.7rem;
        }
    }


    @media screen and (max-width:480px){
        .left-image {
            margin-left: 1rem;
        }
    }
    /*section-5_5*/

.section-5_5{
    /* border: 2px solid rgb(255, 0, 183) ; */
    height: 45rem;
    width: 100%;
    margin-top: 1rem;
}
.section-5_5-main-div{
    display: flex;
    height: 38rem;
    width: 100%;
}
.section-5_5-right-div-Packages{
 /* border: 2px solid rgb(6, 253, 122); */
 height: 38rem;
 width: 48rem;
 margin-left: 4rem;
}
.section-5_5-h1-right{
    margin-top: 9rem;
    margin-left: 1rem;
}
.section-5_5-para-right{
    margin-left: 2rem;
    margin-top: 2rem;
    font-size: 1.2rem;
}
.section-5_5-BookNow-btn{
    background-color: #00000029;
    height: 3rem;
    width: 11rem;
    border-radius: 2rem;
    border: double;
    margin-left: 7rem;
    margin-top: 1rem;
    backdrop-filter: blur(4px);
}
.section-5_5-BookNow-btn:hover{
    background-color: darkcyan;
    color: black;
    outline: auto;
    transition: 0.5s;
}

.section-5_5-left-div{
  /* border: 2px solid rgb(0, 204, 255); */
  height: 38rem;
  width: 36rem;
}
.section-5_5-left-image{
    height: 24rem;
    width: 20rem;
    margin-left: 10rem;
    margin-top: 4rem;
}

@media screen and (max-width:1200px) {
    .section-5_5{
        height: 47rem;
    }
    .section-5_5-left-image{
        margin-left: 6rem;
    }
    .section-5_5-para-right{
        padding: 1rem;
    }
}
@media screen and (max-width:1024px){
    
    .section-5_5-left-image {
        margin-left: 2rem;
    }
    .section-5_5-para-right {
        padding: 0rem;
        font-size: 1rem;
    }
    .section-5_5-BookNow-btn{
        margin-top: 1rem;
    }
}
@media screen and (max-width:885px){
.section-5_5-right-div-Packages{
    margin-left: 1.5rem;
}
.section-5_5-left-image {
    margin-left: 0rem;
    margin-right: 2.5rem;
}}
@media screen and (max-width:835px){
.section-5_5-right-div-Packages {
    margin-left: 0.5rem;
}
}
@media screen and (max-width:768px){
.section-5_5{
    width: 48rem;
    height: 48rem;
}
.section-5_5-para-right {
    margin-left: 1rem;
}
}
@media screen and (max-width:480px){
    .section-5_5 {
        /* border: 2px solid red; */
        width: 100%;
        height: 35rem;
        margin-top: 0rem;
    }
    .section-5_5-main-div {
        /* border: 2px solid orange; */
        height: 30rem;
        width: 100%;
    }
    .section-5_5-right-div-Packages {
        /* border: 2px solid rgb(6, 253, 122); */
        height: 30rem;
        width: 50%;
        margin-left: 0rem;
    }
    .section-5_5-h1-right{
    margin-top: 3rem;
    margin-left: 1.5rem;
    font-size: 1.2rem;
}
.section-5_5-para-right {
    margin-left: 2rem;
    margin-top: 1rem;
    font-size: 0.7rem;
}
.section-5_5-BookNow-btn{
    margin-left: 3rem;
    width: 7rem;
    height: 2.5rem;
    font-size: 0.7rem;
}
.section-5_5-left-div {
    /* border: 2px solid rgb(0, 204, 255); */
    height: 30rem;
    width: 48%;
}
.section-5_5-left-image {
    margin-left: 2rem;
    height: 13rem;
    width: 9rem;
}
}

@media screen and (max-width:360px){
    .section-5_5-left-image {
        /* margin-left: 0rem; */
        margin-right: 1.5rem;
    }
}
