.Corporate-Body {

    background-image: url(../imgs/Beauti.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
    background-attachment: fixed;
    
}




.section-1-BBR{
    /* border: 2px solid orange; */
    display: flex;
    height:65rem ;
    width: 100%;
}
.left-div-BBR{
    /* border: 2px solid rgb(255, 77, 0); */
    height:50rem ;
    width: 50%;
    margin-top: 8rem;
    padding: 4rem;
}
.right-div-BBR{
    /* border: 2px solid rgb(0, 221, 255);  */
    height:50rem ;
    width: 50%;
    margin-top: 8rem;
    margin-left: 2rem;
}
.section-2-h1-right-BBR{
    font-size: 2rem;
}
.section-2-para-right-BBR{
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 1rem;
}
.BookNow-btn-BBR {
    background-color: #00000029;
    height: 3rem;
    width: 11rem;
    border-radius: 2rem;
    border: double;
    margin-left: 7rem;
    margin-top: 1rem;
    backdrop-filter: blur(4px);
}

.BookNow-btn-BBR:hover {
    background-color: darkcyan;
    color: black;
    outline: auto;
    transition: 0.5s;
}


@media screen and (max-width:1200px) {
    
.section-2-para-right-BBR{
    line-height: 1.2rem;
    margin-top: 0rem;
}
}
@media screen and (max-width:1024px){
    .section-1-BBR{
        flex-direction: column;
        width: 100%;
        height: 90rem;
    }
    .left-div-BBR{
        width: 100%;
    }
    .right-div-BBR{
        width: 100%;
        height: 45rem;
        margin-left: 0rem;
    }
    .section-2-h1-right-BBR {
        margin-left: 1rem;
    }
    .section-2-para-right-BBR{
        margin-left: 2rem;
    }
    .left-div-BBR{
        margin-top: 0rem;
    }
    .left-div-BBR {
        height: 35rem;
        padding: 6rem;
}
}
@media screen and (max-width:768px){
.right-div-BBR{
  height: 40rem;
}
}

@media screen and (max-width:480px){
    .section-1-BBR{
        /* border: 2px solid greenyellow; */
        height: 100rem;
    }
    .left-div-BBR {
        height: 16rem;
        padding: 2rem;
        margin-top: -3.5rem;
    }
.section-2-h1-right-BBR {
    text-align: center;
}
.section-2-para-right-BBR {
    text-align: center;
    margin-left: 0rem;
    padding: 0.5rem;
}
.BookNow-btn-BBR{
    margin-left: 7rem;
}
.right-div-BBR {
    height: 61rem;
}
}

@media screen and (max-width:391px){
  .BookNow-btn-BBR {
    margin-left: 6.5rem;
  }
  }
@media screen and (max-width:376px){
  .BookNow-btn-BBR {
    margin-left: 6.2rem;
  }
  }
@media screen and (max-width:360px){
.BookNow-btn-BBR {
  margin-left: 5.7rem;
}
.left-div-BBR {
    margin-top: 7rem;
}
}
span{
    color: #00ffe5;
    font-weight: bolder;
}
