* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Bodoni Moda', serif;
    scroll-behavior: smooth;
}

.Form-Body {
background: url(../imgs/Background.webp);
background-position: center;
background-repeat: no-repeat;
background-size: cover;
/* background-attachment: fixed;    */
height: 90rem;
width: 100%;
margin: 0;
}

@media screen and (max-width:430px) {
    .Form-Body {
        height: 115rem;
    }
}

/*FORM*/
form{
    height: 58rem;
}

.form-heading {
    color: #00ffe5;
    padding-top: 12rem;
    /* text-align: center; */
    margin-left: 38rem;
}


::placeholder {
    color: white;
}

.form-name,
.form-email,
.form-number,
.form-guest,
.form-message {
    margin-left: 33rem;
    backdrop-filter: blur(4px);
    background-color: #000e2546;
    height: 3rem;
    width: 22rem;
    border-radius: .9rem;
    color: white;
    margin-top: 3rem;
}
.form-number{
    height: 3rem;
    width: 10.8rem;
    margin-left: 0rem;
}
.iti--allow-dropdown input[type=tel]{
    padding-left: 40px;
}



.form-date {
    backdrop-filter: blur(4px);
    background-color: #000e2546;
    height: 3rem;
    width: 9rem;
    border-radius: .9rem;
    color: white;
    margin-top: 3rem;
    margin-left: 33rem;
}

.form-time {
    backdrop-filter: blur(4px);
    background-color: #000e2546;
    height: 3rem;
    width: 5rem;
    color: white;
    border-radius: .9rem;
}

.form-hours {
    backdrop-filter: blur(4px);
    background-color: #000e2546;
    height: 3rem;
    width: 8rem;
    color: white;
    border-radius: .9rem;
}



 

.form-div {
    display: inline-block;
}

.form-date,
.form-time,
.form-hours {
    display: inline-block;
}

input[type="date"],
input[type="time"] {
    color: white;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.form input[type="text"],
.form input[type="email"],
.form input[type="number"],
.form input[type="date"],
.form input[type="time"],
.form input[type="number"],
.form textarea {
    border: 1px solid white;
    padding: 10px;
    text-align: center;
}
.phone-country{
    display: inline-block;
    margin-left: 33.5rem;
}
.form-country{
    backdrop-filter: blur(4px);
    background-color: #000e2546;
    height: 3rem;
    width: 10.6rem;
    color: rgb(0, 0, 0);
    border-radius: .9rem;
    margin-top: 3rem;
    /* margin-left: 33rem; */
}

.form-btn{
    height: 2.5rem;
    width:22rem;
    backdrop-filter: blur(4px);
    background-color: #000e2546;
    border-radius: 2rem;
    border: 2px solid white;
    margin-left: 33rem;
    margin-top: 3rem;
    color: white;
}
.form-btn:hover {
    background-color: rgba(255, 255, 255, 0.676);
    color: black;
    outline: auto;
    transition: 0.5s;
}

/* Form's Media Query*/

@media screen and (max-width:1281px){
    form{
        width:100%;
    }
    .form-heading {
        margin-left: 0rem;
        text-align: center;
    }
    .form-name,
    .form-email,
    .form-number,
    .form-guest,
    .form-message,
    .form-btn {
        margin-left: 29rem;
    }
    .form-number{
        margin-left: 0rem;
    }
    .phone-country {
    margin-left: 29.5rem;
}
    
    .form-date{
        margin-left: 29rem;
    }
   
}
@media screen and (max-width:1115px){
    form{
        width:100%;
    }
    .form-heading {
        margin-left: 0rem;
        text-align: center;
    }
    .form-name,
    .form-email,
    .form-number,
    .form-guest,
    .form-message,
    .form-btn {
        margin-left: 24rem;
    }
    .form-number{
        margin-left: 0rem;
    }
    .phone-country {
    margin-left: 24.5rem;
}
    .form-date{
        margin-left: 24rem;
    }
   
}
@media screen and (max-width:885px){
    form{
        width:100%;
        height: 61rem;
    }
    .form-heading {
        margin-left: 0rem;
        text-align: center;
    }
    .form-name,
    .form-email,
    .form-number,
    .form-guest,
    .form-message,
    .form-btn {
        margin-left: 16.5rem;
    }
    .form-number{
        margin-left: 0rem;
    }
    .phone-country {
    margin-left: 16.5rem;
}
    .form-date{
        margin-left: 16.5rem;
    }
}
@media screen and (max-width:835px){

form{
    width:100%;
}
.form-heading {
    margin-left: 0rem;
    text-align: center;
}
.form-name,
.form-email,
.form-number,
.form-guest,
.form-message,
.form-btn {
    margin-left: 15rem;
}
.form-number{
        margin-left: 0rem;
    }
    .phone-country {
    margin-left: 15.5rem;
}
.form-date{
    margin-left: 15rem;
}
}
@media screen and (max-width:801px){

form{
    width:100%;
}
.form-heading {
    margin-left: 0rem;
    text-align: center;
}
.form-name,
.form-email,
.form-number,
.form-guest,
.form-message,
.form-btn {
    margin-left: 14rem;
}
.form-number{
        margin-left: 0rem;
    }
    .phone-country {
    margin-left: 14.5rem;
}
.form-date{
    margin-left: 14rem;
}
}
@media screen and (max-width:768px){

    form{
        width:100%;
    }
    .form-heading {
        margin-left: 0rem;
        text-align: center;
    }
    .form-name,
    .form-email,
    .form-number,
    .form-guest,
    .form-message,
    .form-btn {
        margin-left: 13rem;
    }
    .form-number{
        margin-left: 0rem;
    }
    .phone-country {
    margin-left: 13.5rem;
}
    .form-date{
        margin-left: 13rem;
    }
}
@media screen and (max-width:480px){
    
    .main-section{

        /* border: 2px solid red; */
        width: 100%;
    }
    .form {
        width: 100%;
        /* border: 2px solid yellow; */
    }
    .form-heading {
        margin-left: 0rem;
        text-align: center;
    }
    .form-name,
    .form-email,
    .form-number,
    .form-guest,
    .form-message,
    .form-btn {
        margin-left: 1.5rem;
    }
    .form-number{
        margin-left: 0rem;
    }
    .phone-country {
    margin-left: 2rem;
}
    .form-date{
        margin-left: 1rem;
    }
}
@media screen and (max-width:430px){
.form{

    height: 63em;
}
    .form-heading {
        margin-left: 0rem;
        text-align: center;
    }
    .form-name,
    .form-email,
    .form-number,
    .form-guest,
    .form-message,
    .form-btn {
        margin-left: 2.2rem;
    }
    .form-number{
        margin-left: 0rem;
    }
    .phone-country {
    margin-left: 2.5rem;
}
    .form-date{
        margin-left: 2rem;
    }
}
@media screen and (max-width:415px){
    .form-heading {
        margin-left: 0rem;
        text-align: center;
    }
    .form-name,
    .form-email,
    .form-number,
    .form-guest,
    .form-message,
    .form-btn {
        margin-left: 1.8rem;
    }
    .form-number{
        margin-left: 0rem;
    }
    .phone-country {
    margin-left: 2rem;
}
    .form-date{
        margin-left: 1.6rem;
    }
}
@media screen and (max-width:395px){
    .form-heading {
        margin-left: 0rem;
        text-align: center;
    }
    .form-name,
    .form-email,
    .form-number,
    .form-guest,
    .form-message,
    .form-btn {
        margin-left: 1.3rem;
    }
    .form-number{
        margin-left: 0rem;
    }
    .phone-country {
    margin-left: 1.5rem;
}
    .form-date{
        margin-left: 1.1rem;
    }
}
@media screen and (max-width:376px){
    .form-heading {
        margin-left: 0rem;
        text-align: center;
    }
    .form-name,
    .form-email,
    .form-number,
    .form-guest,
    .form-message,
    .form-btn {
        margin-left: 0.5rem;
    }
    .form-number{
        margin-left: 0rem;
    }
    .phone-country {
    margin-left: 0.6rem;
}
    
    .form-date{
        margin-left: 0.3rem;
    }
}
@media screen and (max-width:361px){
    .form-heading {
        margin-left: 0rem;
        text-align: center;
    }
    .form-name,
    .form-email,
    .form-number,
    .form-guest,
    .form-message,
    .form-btn{
        margin-left: 3.8rem;
        width:15rem;
    }
   
    .form-hours{
        margin-left: 0rem;
        width: 5rem;
    }
    .form-number{
        width: 8rem;
        margin-left: 0rem;
    }
    .phone-country {
        margin-left: 5rem;
}
    .form-date{
        margin-left: 3.5rem;
        width: 5rem;
    }
    .iti--allow-dropdown input[type=tel] {
    padding-left: 40px;
    width: 7rem;
}
}


.Countrycode-input{
    top: 1rem;
    left: -0.2rem;
}

.Countrycode-input input{
    backdrop-filter: blur(4px)!important;
    background-color: #000e2546!important;
    height: 3rem!important;
    width: 21.7rem!important;
    color: white!important;
    border-radius: 0.9rem!important;
}

.Countrycode-input  .flag-dropdown{
    backdrop-filter: blur(4px)!important;
    background-color: #000e2546!important;
    /* height: 3rem!important; */
    /* width: 8rem!important; */
    color: white!important;
    /* border-radius: 0.9rem!important; */
   border-bottom-left-radius:0.9rem!important ;
   border-top-left-radius:0.9rem!important ;
}


@media screen and (max-width:360px) {
    .Countrycode-input {
        left: -1rem!important;
    }
    .Countrycode-input input{
        width: 14.5rem!important;
    }
}