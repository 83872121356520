.Body-Policy{
    background-color: rgb(108, 168, 168);
    height: 100%;
    color: white;
}
.content{
    padding-top: 5rem;
    text-align: center;
 }
 .content p{
    margin-top: 1rem;
    font-size: 1.3vw;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
 }
 .content h1{
    padding-top: 5rem;
    text-align: center;

 }
 .third-para{
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: 4rem;
 }



 .heading{
    text-align: center;
    font-weight: bold;
    padding-top: 4rem;
}

.heading h3{
    margin-top: 4rem;
}
.heading p{
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: 1rem;
    font-size: 1.3vw;
    font-family: 'Poppins', sans-serif;

}


/*Media Queries*/


@media screen and (max-width:885px) {
    .content p{
        font-size: 1.9vw;
    }
    .heading p{
        font-size: 1.9vw;
    }
}
@media screen and (max-width:835px) {
    .content p{
        font-size: 1.9vw;
    }
    .heading p{
        font-size: 1.9vw;
    }
}


@media screen and (max-width:480px) {
    .content p{
        font-size: 3.2vw;
    }
    .heading p{
        font-size: 3.2vw;
    }
}