*{
    font-family: 'Bodoni Moda', serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    color: white;
}
.Home-Body{
    background-image: url(../imgs/Vodka.webp);
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    scroll-behavior: smooth;
}
/* SECTION-1 */

.section-1-1{
    display: flex;
    height:40rem;
    width: 100%;
  }
  .head-left-section-1{
    margin-top: 10rem;
    height: 30rem;
    width: 50rem;
  }
  .head-heading-section-1{
    margin-left: 7rem;
    font-size: 5rem;
    font-weight: 600;
  }
  .head-para-section-1{
    margin-left: 7rem;
    font-size: 1.5rem;
    margin-top: 2rem;
  }
  .head-btn-section-1{
    background-color: #00000029;
    height: 3rem;
    width: 11rem;
    border-radius: 2rem;
    border: double;
    margin-left: 7rem;
    margin-top: 4rem;
    backdrop-filter: blur(4px);
  }
  .head-btn-section-1:hover{
    background-color: darkcyan;
    color: black;
    outline: auto;
    transition: 0.5s;
  }
  .head-btn-section-1-2{
    background-color: #00000029;
    height: 3rem;
    width: 11rem;
    border-radius: 2rem;
    border: double;
    margin-left: 1rem;
    margin-top: 4rem;
    backdrop-filter: blur(4px);
  }
  .head-btn-section-1-2:hover{
    background-color: darkcyan;
    color: black;
    outline: auto;
    transition: 0.5s;
  }

  .head-right{
    height: 30rem;
    width: 45rem;
    margin-top: 10rem;
  }
  .head-images-section-1{
    height: 15rem;
    width: 10rem;
    margin-top: 4rem;
    margin-left: 1rem;
    /* border-radius: 2.5rem; */
    outline: 1px solid white;
  }

  /*SECTION-1 MEDIAQUERIES*/

@media screen and (max-width:1200px) {
  .head-images-section-1 {
    height: 13rem;
    width: 8.5rem;
  }
}
@media screen and (max-width:1125px) {
  .head-heading-section-1 {
    margin-left: 5rem;
    font-size: 4rem;
  }
.head-para-section-1 {
  margin-left: 4rem;
  font-size: 1.5rem;
  margin-top: 1rem;
}
}
@media screen and (max-width:1024px) {

  .head-left-section-1 {
    width: 37rem;
  }

  .head-heading-section-1 {
    margin-left: 3rem;
    font-size: 3rem;
  }

  .head-para-section-1 {
    margin-left: 3.5rem;
    font-size: 1.4rem;
  }

  .head-btn-section-1 {
    width: 9rem;
    margin-left: 2.5rem;
  }

  .head-btn-section-1-2 {
    width: 9rem;
    margin-left: 0rem;
  }

  .head-images-section-1 {
    height: 12rem;
    width: 7.5rem;
  }
}
@media screen and (max-width:885px){
  .head-heading-section-1 {
    margin-left: 1.5rem;
  }
.head-para-section-1 {
  margin-left: 1.5rem;
}
}
@media screen and (max-width:768px) {
  .section-1-1 {
    display: flex;
    flex-direction: column;
    width: 48rem;
    height: 55rem;
   
  }

  .head-left-section-1 {
    margin-left: 6rem;
  }

  .head-heading-section-1 {
    font-size: 4rem;
    text-align: center;
  }

  .head-para-section-1 {
    text-align: center;
    font-size: 2rem;
  }

  .head-btn-section-1 {
    margin-left: 9.1rem;
  }

  .head-right {
    margin-top: 1rem;
    width: 33rem;
    margin-left: 9.5rem;
  }

  .head-images-section-1 {
    height: 13rem;
    width: 8.5rem;
  }
}
@media screen and (max-width:430px) {

  .section-1-1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50rem;
  }
  .head-left-section-1 {
    width: 100%;
    height: 20rem;
    margin-left: 0rem;
  }

  .head-heading-section-1 {
    font-size: 2rem;
    text-align: center;
    margin-left: 0rem;
  }

  .head-para-section-1 {
    text-align: center;
    font-size: 1rem;
    margin-left: 0rem;
    padding: 0rem 1rem;
  }

  .head-btn-section-1 {
    margin-left: 4rem;
  }

  .head-right {
    width: 100%;
    margin-left: 0rem;
  }

  .head-images-section-1 {
    height: 9rem;
    width: 6.5rem;
    margin-left: 1.9rem;
  }
}
@media screen and (max-width:415px){
  .head-images-section-1 {
    margin-left: 1.6rem;
  }
}
@media screen and (max-width:395px){
  .head-btn-section-1 {
    margin-left: 3rem;
}
  .head-images-section-1 {
    margin-left: 1.2rem;
  }
}
@media screen and (max-width:376px){

  .head-images-section-1{
    margin-left: 0.7rem;
  }
  .head-btn-section-1 {
    margin-left: 2rem;
}

}

 /* section-2-2 */


 .section-2-2{
    display: flex;
    /* border: 2px solid black; */
    height: 30rem;
    width: 100%;
    margin-top: 1rem;
  }
  .section-2-2-left{
    /* border: 2px solid rgb(211, 197, 7); */
    width: 50rem;
    height:30rem ;
  }
  .section-2-2-left-heading{
    margin-top: 3rem;
    margin-left: 6rem;
    font-size: 3rem;
    font-weight: 600;
  }
  .section-2-2-left-para{
    margin-left: 6rem;
    font-size: 1.5rem;
    margin-top: 2rem;
    padding: 1rem 2rem;
  }
  .section-2-2-right{
    display: flex;
    /* border: 2px solid rgb(7, 31, 211); */
    width: 30rem;
    margin-left: 2rem;
  }
  .section-2-2-images-1{
    height: 20rem;
    width: 18rem;
    position: relative;
    left: 0rem;
    top: 6rem;
  }
  .section-2-2-images-2{
    position: relative;
    top: -5rem;
    left: -8rem;
    height: 20rem;
    width: 18rem;
    z-index: 0;
  }

/*section-2-2 MEDIAQUERIES*/

@media screen and (max-width:1280px) {
  .section-2-2-left-heading {
    margin-left: 2rem;
  }
  .section-2-2-images-1 {
    top: 0rem;
}
  .section-2-2-images-2 {
    position: relative;
    top: -9rem;
    left: 8rem;
  }


  .section-2-2-left-para {
    font-size: 1.2rem;
    margin-left: 0rem;
    margin-top: 0rem;
  }
}

@media screen and (max-width:1024px) {
  .section-2-2 {
    height: 35rem;
  }

  .section-2-2-left-heading {
    margin-left: 3rem;
    font-size: 2.5rem;
  }

  .section-2-2-left-para {
    margin-left: 1rem;
    font-size: 1.2rem;
    padding: 2rem 0rem;
  }

  .section-2-2-right {
    width: 24rem;
  }

  .section-2-2-images-1 {
    height: 17rem;
    width: 14rem;
  }

  .section-2-2-images-2 {
    height: 18rem;
    width: 16rem;
  }
}
@media screen and (max-width:885px){
.section-2-2-images-2 {
  left: 4rem;
}
}
@media screen and (max-width: 885px){
.section-2-2-images-2 {
    left: 3rem;
}}
@media screen and (max-width: 800px){
  .section-2-2-images-2 {
      left: 2rem;
  }}
@media screen and (max-width:768px) {

  .section-2-2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 58rem;
    margin-top: 5rem;
    
  }

  .section-2-2-left {
    height: 33rem;
    width: 47rem;
  }

  .section-2-2-left-heading {
    text-align: center;
  }

  .section-2-2-left-para {
    text-align: center;
    margin: 0rem 3rem;
  }

  .section-2-2-right {
    width: 25rem;
    height: 28rem;
    margin-left: 13rem;
    margin-top: 5rem;
  }

  .section-2-2-images-1 {
    height: 19rem;
    width: 15rem;
    margin-left: 12rem;
  }

  .section-2-2-images-2 {
    height: 20rem;
    width: 17rem;
    left: -6rem;
  }
}

@media screen and (max-width:430px) {
  .section-2-2 {
    /* border: 2px solid orange; */
    width: 100%;
    height: 50rem;
    margin-top: 0rem;
  }

  .section-2-2-left {
    width: 100%;
    height: 36rem;

  }

  .section-2-2-left-heading {
    margin-left: 0rem;
    font-size: 2rem;
  }

  .section-2-2-left-para {
    font-size: 1rem;
    margin: 0rem 3rem;
  }

  .section-2-2-right {
    width: 100%;
  }

  .section-2-2-right {
    margin-left: 0rem;
    margin-top: 0rem;
    height: 20rem;
  }

  .section-2-2-images-1 {
    height: 9rem;
    width: 7rem;
    margin-top: -2rem;
    margin-left: 7.5rem;
  }

  .section-2-2-images-2 {
    height: 10rem;
    width: 8.5rem;
    margin-left: 2.5rem;
  }

}

@media screen and (max-width:395px){
  .section-2-2-left{
  height: 37rem;
}
  .section-2-2-images-2 {
  
    margin-left: 0rem;
  }
}

@media screen and (max-width:375px){
  .section-2-2-left{
    height: 39rem;
  }
  .section-2-2-images-2 {
  
    margin-left: 0rem;
  }
  .section-2-2-images-1 {
    height: 9rem;
    width: 7rem;
    margin-top: -2rem;
    margin-left: 6rem;
}
}
@media screen and (max-width:360px){
  .section-2-2-images-2 {
    margin-left: 0rem;
    margin-top: 9rem;
}


.section-2-2-images-1 {
margin-left: 6rem;
margin-top: 5rem;
}
}


/*section-3-3*/

.section-3-3{
    /* border: 2px solid yellow; */
    margin-top: 1rem;
    height: 50rem;
  }
  .section-3-3-div-1{
    /* border:2px solid rgb(238, 111, 0); */
    height: 12rem;
    width: 100%;
  }
  .section-3-3-heading{
text-align: center;
font-size: 3rem;
font-weight: 600;
  }
  .section-3-3-para{
    text-align: center;
    font-size: 1.8rem;
    padding: 0rem 11rem;
  }

  .section-3-3-div-2{
    display: flex;
    flex-wrap: wrap ;
    /* border:2px solid rgb(18, 79, 13); */
    height: 30rem;
    width: 100%;
    margin-top: 5.5rem;
  }
  .section-3-3-images{
    height: 22rem;
    width: 15rem;
    margin: 0rem 2rem;
    border:1px solid white;
    position: relative;
    left: 16.5rem;
  }
  .section-3-3-btn{
    background-color: #00000029;
    height: 3rem;
    width: 11rem;
    border-radius: 2rem;
    border: double;
    margin-left: 39.5rem;
    /* margin-top: 4rem; */
    backdrop-filter: blur(4px);
  }
  .section-3-3-btn:hover{
    background-color: darkcyan;
    color: black;
    outline: auto;
    transition: 0.5s;
  }

/*section-3-3 MEDIAQUERIES*/

@media screen and (max-width:1280px) {
  .section-3-3-images {
    position: relative;
    left: 11.5rem;
    top: 2rem;
  }
  .section-3-3-btn{
  margin-left: 34.5rem;
}
}
@media screen and (max-width:1115px){
  .section-3-3-para{
    font-size: 1.5rem;
  }
  .section-3-3-images {
    left: 6.5rem;
  }
  .section-3-3-btn {
    margin-left: 29.5rem;
}
}

@media screen and (max-width:1024px) {
  .section-3-3-para {
    padding: 0 0;
    font-size: 1.5rem;
    margin-left: 0rem;
  }

  .section-3-3-images {
    position: relative;
    left: 2.5rem;
    top: 5rem;
    height: 15rem;
    width: 11rem;
  }

  .section-3-3-btn {
    position: relative;
    right: 19.5rem;
    top: -5rem;
    bottom: 8rem;
  }
}

@media screen and (max-width:885px){
  .section-3-3-images {
    left: 5rem;
  }
  .section-3-3-btn {
    right: 7rem;
  }
  }
  @media screen and (max-width:835px){
    .section-3-3-images {
      left: 3.5rem;
  }
  .section-3-3-btn {
    right: 9rem;
}
}
@media screen and (max-width:800px){
  .section-3-3-images {
    left: 3rem;
}
.section-3-3-btn {
  right: 9.5rem;
}
}
@media screen and (max-width:768px) {
  .section-3-3 {
    width: 100%;
    
  }

  .section-3-3-div-1 {
    width: 100%;
  }

  .section-3-3-para {
    font-size: 1.5rem;
    padding: 0 1rem;

  }
  .section-3-3-images {
    left: 2rem;
}
.section-3-3-btn {
  right: 10.5rem;
}
}
@media screen and (max-width:480px) {

.section-3-3 {
  width: 100%;
  height: 80rem;
  /* border: 2px solid red; */
}

.section-3-3-div-1 {
  width: 100%;
}
.section-3-3-heading{
  font-size: 2rem;
}
.section-3-3-para {
font-size: 1rem;
margin: 0rem 3rem;
padding: 2rem 0rem;
}

.section-3-3-div-2 {
  /* border: 2px solid yellow; */
  width: 100%;
  height: 49rem;
  margin-top: 10rem;
  margin-left: 0rem;
}
.section-3-3-images{
  left: 6.2rem;
top: 0rem;
height: 14rem;
width: 10rem;
}

.section-3-3-btn {
  left: 7.8rem;
  top: 2rem;
  bottom: 0rem;
  margin-left: 0rem;
}
}


@media screen and (max-width:394px){
.section-3-3-images {
  left: 5rem;
}
.section-3-3-btn {
  left: 6.4rem;
}
}
@media screen and (max-width:360px){
.section-3-3-images {
  left: 4.2rem;
}
.section-3-3-btn {
  left: 5.8rem;
}
}




.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 768px){
  .whatsapp-icon {
    margin-top: 10px;
  }
  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}
@media screen and (max-width: 480px){
    .whatsapp-icon {
    margin-top: 10px;
  }
  .whatsapp_float {
    width: 40px;
    height: 40px;
    margin-bottom: 5.5rem;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}