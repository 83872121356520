* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Bodoni Moda', serif;
    color: white;
    scroll-behavior: smooth;
}
.Yacht-Body{

    background-image: url(../imgs/Beauti.webp);
    background-color: grey;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
    background-attachment: fixed;
    
}
.section-6{
    display: flex;
    /* height: 50rem; */
    width: 100%;
    padding-top: 5.5rem;
}
.first-div{
    /* border: 2px solid rgb(255, 0, 255); */
    height: 43rem;
    width: 40%;
    }
.first-div-h1{
    text-align: START;
    color: white;
    margin-top: 6rem;
    MARGIN-LEFT: 4rem;
    font-size: 3rem;
}
.first-div-p{
    text-align: start;
    color: white;
    margin-top: 0rem;
    margin-left: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 1.3rem;
}

.second-div{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
/* border: 2px solid orange; */
height: 43rem;
width: 60%;
}
.second-div img{
    height: 11rem;
    width: 15rem;
  margin: 1rem;
}
.section-6-BookNow-btn{
    background-color: #00000029;
    height: 3rem;
    width: 11rem;
    border-radius: 2rem;
    border: double;
    margin-left: 12rem;
    /* margin-top: 4rem; */
    backdrop-filter: blur(4px);
}
.section-6-BookNow-btn:hover{
    background-color: darkcyan;
    color: black;
    outline: auto;
    transition: 0.5s;
}

@media screen and (max-width:1369px) {
    .second-div {
        justify-content: center;
    }
    .second-div img {
        width: 14rem;
    }
}
@media screen and (max-width:1289px){
    .second-div img {
    width: 12rem;
    height: 9rem;
}
}
@media screen and (max-width:1200px){
.section-6{
    height: 53rem;
}
.first-div{
    height: 52rem;
}
.second-div{
    margin-top: 6rem;
    height: 38rem;
}
.second-div img {
    width: 10.5rem;
    height: 8.5rem;
}
}
@media screen and (max-width:1024px){
    .section-6 {
        height: 65rem;
    width: 100%;
}
.first-div{
    height: 55rem;
    margin-top: 6rem;
    margin-left: 2rem;
}
.first-div-h1{
    margin-top: 5rem;
    MARGIN-LEFT: 1.5rem;
    font-size: 2.5rem;
}
.first-div-p{
    font-size: 1.2rem;
    margin-left: 0rem;
}
.second-div{
    height: 55rem;
}
.second-div img {
    width: 12rem;
    height: 9rem;
}
.section-6-BookNow-btn{
    margin-left: 6rem;
}

}
@media screen and (max-width:768px){

    .section-6{
        height: 65rem;
        width: 48rem;
    }
    .first-div {
        margin-left: 1rem;
    }
    .second-div img {
    width: 12rem;
    height: 8.5rem;
}
}
@media screen and (max-width:430px){
    .section-6 {
    width: 100%;
    height: 93rem;
    flex-direction: column;
   
}
.first-div {
    width: 100%;
    height: 35rem;
    margin-top: 0rem;
    margin-left: 0rem;
}
.first-div-h1 {
    text-align: center;
    margin-top: 3rem;
    MARGIN-LEFT: 0rem;
    font-size: 2rem;
}
.first-div-p{
    text-align: center;
    padding: 1rem 0.5rem;
}

.second-div{
    width: 100%;
    margin-top: 2rem;
}
.second-div img {
    width: 9.5rem;
    height: 7rem;
}
.section-6-BookNow-btn {
    margin-left: 7.9rem;
}

}
@media screen and (max-width:415px){
    .section-6-BookNow-btn {
        margin-left: 7.5rem;
    }
}
@media screen and (max-width:394px){
    .section-6-BookNow-btn {
        margin-left: 7rem;
    }
}
@media screen and (max-width:375px){
    .section-6-BookNow-btn {
        margin-left: 6rem;
    }
}
@media screen and (max-width:365px){
    .second-div img {
    width: 8.5rem;
}
}

span{
    color: #00ffe5;
    font-weight: bolder;
}

/*section-2*/
.section-2{
    /* border: 2px solid orange; */
    height: 85rem;
    width: 100%;
    margin-top: 5rem;
    /* display: flex; */
}

.yachts-para{
    font-size: 1.3rem;
 padding: 0rem 5rem;
    text-align: center;
}
.sec2-main-div{
    /* border: 2px solid yellow; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 70rem;
    width: 100%;
    margin-top: 5rem;
}
@media screen and (max-width:1200px) {
    .section-2{
        height: 110rem;
    }
    .sec2-main-div{
        height: 97rem;
    }
    .yachts-para {
        font-size: 1rem;
    }
}
@media screen and (max-width:1024px){
    .section-2 {
        height: 120rem;
        width: 100%;
    }
    .sec2-main-div {
        height: 108rem;
    }
    .yachts-para{
        padding: 0rem 6rem;
    }
    
}

@media screen and (max-width:786px){
    .section-2 {
        height: 120rem;
        width: 48rem;
    }
}
@media screen and (max-width:480px){
    .section-2 {
        height: 210rem;
        width: 100%;
    }
    .yachts-para {
        font-size: 0.9rem;
        padding: 0rem 3rem;
    }
}





/*CARDS*/
.card-1{
    align-items: center;
    width: 20rem;
    height: 25rem;
    border-radius: 0.5rem;
    background-color: #1c988c;
    margin: 3rem;
}
.card-1-img{
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}
.card-1-heaing{
text-align: center;
font-weight: bolder;
margin-top: 1rem;
}
.card-1-para{
    text-align: center;
    margin-top: 1rem;
}
a{
    text-decoration: none;
}

.card-1-btn{
    background-color: transparent;
    border:none;
    border-radius: 10rem;
    color:rgb(0, 0, 0);
    font-size: 1rem;
    margin-left: 4.3rem;
    font-weight: bolder;
}
.card-1-btn:hover{
    color: white;
  
    border: 1px solid rgb(255, 255, 255);
    background-color: #54ddcf9f;
transition: 0.5s all;
}
.card-2{
    align-items: center;
    width: 20rem;
    height: 25rem;
    border-radius: 0.5rem;
    background-color: #1c988c;
    margin: 3rem;
}
.card-2-img{
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}
.card-2-heaing{
text-align: center;
font-weight: bolder;
margin-top: 1rem;
}
.card-2-para{
    text-align: center;
    margin-top: 1rem;
}
a{
    text-decoration: none;
}

.card-2-btn{
    background-color: transparent;
    border:none;
    border-radius: 10rem;
    color:rgb(0, 0, 0);
    font-size: 1rem;
    margin-left: 4rem;
    font-weight: bolder;
}
.card-2-btn:hover{
    color: white;
    border: 1px solid rgb(255, 255, 255);
    background-color: #54ddcf9f;
transition: 0.5s all;
}



.card-3{
    align-items: center;
    width: 20rem;
    height: 25rem;
    border-radius: 0.5rem;
    background-color: #1c988c;
    margin: 3rem;
}
.card-3-img{
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    height: 14rem;
}
.card-3-heaing{
text-align: center;
font-weight: bolder;
margin-top: 1rem;
}
.card-3-para{
    text-align: center;
    margin-top: 1rem;
}
a{
    text-decoration: none;
}

.card-3-btn{
    background-color: transparent;
    border:none;
    border-radius: 10rem;
    color:rgb(0, 0, 0);
    font-size: 1rem;
    margin-left: 3.7rem;
    font-weight: bolder;
}
.card-3-btn:hover{
    color: white;
    border: 1px solid rgb(255, 255, 255);
    background-color: #54ddcf9f;
transition: 0.5s all;
}

@media screen and (max-width:820px){
    .card-1,.card-2,.card-3{
        margin: 2rem;
    }
}