.Sunset-Body{
background-image: url(../imgs/Beauti.webp);
background-position: center;
background-repeat: no-repeat;
background-size: cover;
height: auto;
width: 100%;
background-attachment: fixed;
}



.section-1-SS{
    /* border: 2px solid orange; */
    display: flex;
    height: 50rem;
    width: 100%;
}
.left-div-SS{
    /* border: 2px solid rgb(255, 77, 0); */
    height: 40rem;
    width: 50%;
    margin-top: 8rem;
    padding: 4rem;
}
.right-div-SS{
    /* border: 2px solid rgb(0, 221, 255);  */
    height: 40rem;
    width: 50%;
    margin-top: 8rem;
    margin-left: 2rem;
}
.section-2-h1-right-SS{
    font-size: 2rem;
}
.section-2-para-right-SS{
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 1rem;
}
.BookNow-btn-SS {
    background-color: #00000029;
    height: 3rem;
    width: 11rem;
    border-radius: 2rem;
    border: double;
    margin-left: 7rem;
    margin-top: 1rem;
    backdrop-filter: blur(4px);
}

.BookNow-btn-SS:hover {
    background-color: darkcyan;
    color: black;
    outline: auto;
    transition: 0.5s;
}


@media screen and (max-width:1200px) {
    
.section-2-para-right-SS{
    line-height: 1.2rem;
    margin-top: 0rem;
}
}
@media screen and (max-width:1024px){
    .section-1-SS{
        flex-direction: column;
        width: 100%;
        height: 80rem;
    }
    .left-div-SS{
        width: 100%;
    }
    .right-div-SS{
        width: 100%;
        height: 30rem;
        margin-left: 0rem;
    }
    .section-2-h1-right-SS {
        margin-left: 1rem;
    }
    .section-2-para-right-SS{
        margin-left: 2rem;
    }
    .left-div-SS{
        margin-top: 0rem;
    }
    .left-div-SS {
        height: 35rem;
        padding: 6rem;
}
}
@media screen and (max-width:768px){
  .section-1-SS{
    height: 80rem;
  }
.right-div-SS{
  height: 30rem;
}
}
@media screen and (max-width:480px){
    .section-1-SS{
        /* border: 2px solid greenyellow; */
        height: 82rem;
    }
    .left-div-SS {
        height: 16rem;
        padding: 2rem;
        margin-top: 1rem;
    }
.section-2-h1-right-SS {
    text-align: center;
}
.section-2-para-right-SS {
    text-align: center;
    margin-left: 0rem;
    padding: 0.5rem;
}
.BookNow-btn-SS{
    margin-left: 7rem;
}
.right-div-SS {
    height: 47rem;
}
}
@media screen and (max-width:376px){
  .BookNow-btn-SS{
    margin-left: 6.1rem;
}
}
@media screen and (max-width:360px){
  .BookNow-btn-SS{
    margin-left: 5.8rem;
}
}

span{
    color: #00ffe5;
    font-weight: bolder;
}