.Yacht-Body{

    background-image: url(../imgs/Beauti.webp);
    background-color: grey;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
    background-attachment: fixed;
    
}
.section-1-yachtThree{
    /* border: 2px solid orange; */
    display: flex;
    height:50rem ;
    width: 100%;
}
.left-div-yachtThree{
    /* border: 2px solid rgb(255, 77, 0); */
    height: 35rem;
    width: 50%;
    margin-top: 8rem;
    padding: 4rem;
}
.right-div{
    /* border: 2px solid rgb(0, 221, 255);  */
    height: 35rem;
    width: 50%;
    margin-top: 8rem;
    margin-left: 2rem;
}
.section-2-h1-right{
    text-align: center;
    font-size: 2rem;
    margin-top: 6rem;
}
.section-2-para-right{
    margin-left: 0rem;
    text-align: center;
    margin-top: 2rem;
}
.BookNow-btn-YachtsThree {
    background-color: #00000029;
    height: 3rem;
    width: 11rem;
    border-radius: 2rem;
    border: double;
    margin-left: 17rem;
    margin-top: 1rem;
    backdrop-filter: blur(4px);
}

.BookNow-btn-YachtsThree:hover {
    background-color: darkcyan;
    color: black;
    outline: auto;
    transition: 0.5s;
}
.pic-1{
    height: 25.5rem;
}


@media screen and (max-width:1280px) {
    .BookNow-btn-YachtsThree {  
        margin-left: 14.2rem;
    }
    
.section-2-para-right{
    line-height: 1.2rem;
    margin-top: 0rem;
}
}


@media screen and (max-width:1115px){
.BookNow-btn-YachtsThree {
    margin-left: 11.7rem;
}
}
@media screen and (max-width:1024px){
    .section-1-yachtThree{
        flex-direction: column;
        width: 100%;
        height: 78rem;
    }
    .left-div-yachtThree{
        width: 100%;
    }
    .right-div{
        width: 100%;
        height: 45rem;
        margin-left: 0rem;
    }
    .section-2-h1-right {
        margin-left: 0rem;
    }
    .section-2-para-right{
        margin-left: 0rem;
    }
    .left-div-yachtThree{
        margin-top: 0rem;
    }
    .left-div-yachtThree {
        height: 35rem;
        padding: 6rem;
}
.BookNow-btn-YachtsThree {
    margin-left: 21.7rem;
}
}
@media screen and (max-width:835px){
    .BookNow-btn-YachtsThree {
        margin-left: 20.7rem;
    }
}
@media screen and (max-width:820px){
    .BookNow-btn-YachtsThree {
        margin-left: 20rem;
    }
}
@media screen and (max-width:800px){
    .BookNow-btn-YachtsThree {
        margin-left: 19.5rem;
    }
}
@media screen and (max-width:768px){
.right-div{
    height: 55rem;
}
.BookNow-btn-YachtsThree{
    margin-left: 18rem;
}
}
@media screen and (max-width:480px){
    .section-1-yachtThree{
        /* border: 2px solid greenyellow; */
        height: 64rem;
    }
    .left-div-yachtThree {
        height: 35rem;
        padding: 2rem;
        padding-top: 11rem;
        margin-top: -3.5rem;
    }
.section-2-h1-right {
    text-align: center;
}
.section-2-para-right {
    text-align: center;
    margin-left: 0rem;
    padding: 0.5rem;
}
.BookNow-btn-YachtsThree{
    margin-left: 8rem;
}
.right-div {
    margin-top: -4rem;
    height: 33rem;
}
.pic-1 {
    height: 18.5rem;
}
}

@media screen and (max-width:415px){

.BookNow-btn-YachtsThree{
    margin-left: 7.5rem;
}
}
@media screen and (max-width:395px){

    .BookNow-btn-YachtsThree{
        margin-left: 7rem;
    }
    }
    @media screen and (max-width:390px){

        .BookNow-btn-YachtsThree{
            margin-left: 6.9rem;
        }
        }
        @media screen and (max-width:375px){

            .BookNow-btn-YachtsThree{
                margin-left: 5.9rem;
            }
            }

span{
    color: #00ffe5;
    font-weight: bolder;
}