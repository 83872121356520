body{
    background-color: grey;
}

hr{
  color: white;
  height: .2rem !important;
  margin-top: 4rem;
}
.footer-section{
  display: flex;
  margin-top: 3rem;
  margin-bottom: 5rem;
  /* flex-direction: row; */
}
footer img{
  width: 8rem;
  height: 10rem;
  margin-left: 10rem;
}
.options{
  color: white;
  margin-left: 25rem;
  margin-top: 3rem;
}
.options a{
  color: white;
  text-decoration: none;
  margin-left: 0rem;
}
.options a:hover{
  color: #2A8C82;
  transition: .5s all;
}
.packages{
  color: white;
  margin-left: 5rem;
  margin-top: 3rem;
}
.packages a{
  color: white;
  text-decoration: none;
  margin-left: 0rem;
}
.packages a:hover{
  color: #2A8C82;
  transition: .5s all;
}
.terms{
  color: white;
  margin-left: 2rem;
  margin-top: 3rem;
}
.terms a{
  color: white;
  text-decoration: none;
  margin-left: 0rem;
}
.terms a:hover{
  color: #2A8C82;
  transition: .5s all;
}
.social-links{
  margin-left: 5rem;
  font-size: 1.9rem;
  margin-top: 3rem;
  text-decoration: none;
}
.social-links a{
  color: white;
  margin: 0.2rem
}

.social-links a:hover{
  color: #2A8C82;
  transition: .5s all;
}

.footer-end{
text-align: center;
  color: white;
  font-size: 15px;
  font-weight: bolder;
  margin-bottom: 0rem;
}



@media screen and (max-width:1200px) {
  footer img {
    margin-left: 5rem;
  }

  .options {
    margin-left: 13rem;
  }
}
@media screen and (max-width:1024px) {

  body {
    height: 110rem;
  }
  .footer-section {
   
    height: 25rem;
      margin-top: 0rem;
      margin-bottom: -4.8rem;
  }

  .options a {
    margin-left: 0rem;
  }
  

  .social-links {
    margin-left: 3rem;
  }
}
@media screen and (max-width:835px){
  .footer-section {
   height: 27rem;
  }
  .packages{
    margin-left: 3em;
  }
}
@media screen and (max-width:801px){
  .footer-section {
   height: 29rem;
  }
  .packages{
    margin-left: 3em;
  }
}
@media screen and (max-width:768px) {
  body {
    height: 90rem;
  }
    footer {
      width: 100%;
      margin-top: -5rem;
  }
  
  .footer-section {
   
    height: 25rem;
      margin-top: 0rem;
      margin-bottom: -6rem;
  }

  footer img {
    width: 7rem;
    height: 8rem;
    margin-left: 3rem;
  }

  .options {
    margin-left: 10rem;
  }

  .packages {
    margin-left: 1.3rem;
  }

  .terms {
    margin-left: 1.3rem;
  }

}
@media screen and (max-width:480px){
  
  hr{
    margin-top: 1rem;
  }
footer {
  width: 100%;
  margin-top: 6rem;
}
.footer-section {
  display: flex;
  flex-direction: column;
  height: 45rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
}
footer img {
  margin-left: 8rem;
}
.options {
  margin-left: 0rem;
  text-align: center;
}
.packages {
  margin-left: 0rem;
  text-align: center;
}
.packages a{
  margin-left: 0rem;
}
h4{
  margin-bottom: -15px;
}
.terms {
  margin-left: 0rem;
  text-align: center;
}
.terms a{
  margin-left: 0rem;
}
.social-links {
  margin-left: 0rem;
  text-align: center;
}
}
@media screen and (max-width:430px){
  body{
    height: 115rem;
  }
  footer{
    margin-top: 0rem;
  }

  .footer-section {
    height: 49rem;
    margin-bottom: -3.67rem;
}
  footer img {
  margin-left: 9.1rem;
}
}
@media screen and (max-width:415px){
  footer img {
  margin-left: 8.8rem;
}
}
@media screen and (max-width:394px){
  footer img {
  margin-left: 8rem;
}
}
@media screen and (max-width:375px){
  footer img {
  margin-left: 7.5rem;
}
}
@media screen and (max-width:360px){
 
 
  footer img {
  margin-left: 7.1rem;
}
}